import { useCallback, type SyntheticEvent } from "react";
import { isPerfumeSummaryEmpty, PerfumeSummary } from "@/models";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import { FormulaNumber } from "@/components/formulaNumber";
import { dataAttribute } from "./domUtils";
import type { AlgoliaHit } from "instantsearch.js";

// export type PerfumeSearchHit = AlgoliaHit<PerfumeSummary>;

export type ListOfPerfumesProps = {
  perfumes: PerfumeSummary[] | null;
  selectPerfumeId: (perfumeId: string) => void;
};

export default function ListOfPerfumesSearchHits({
  perfumes,
  selectPerfumeId,
}: ListOfPerfumesProps) {
  const handleSelectPerfumeId = useCallback(
    (event: SyntheticEvent) => {
      const perfumeId = dataAttribute(event, "perfumeId");
      selectPerfumeId(perfumeId);
      // navigate(PageLink.perfumeDetails(recipe_id));
    },
    [selectPerfumeId],
  );

  return (
    <List>
      {(perfumes || []).map((perfume) => (
        <ListItemButton
          key={perfume.recipeId}
          data-perfume-id={perfume.recipeId}
          onClick={handleSelectPerfumeId}
        >
          <ListItemAvatar>
            <Avatar>
              <Icon color="primary">
                <img width={24} src="/perfume-svgrepo-com.svg" alt="perfume" />
              </Icon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <FormulaNumber formulaNumber={perfume.formulaNumber} /> {perfume.name}
              </>
            }
            secondary={
              <>
                {isPerfumeSummaryEmpty(perfume) && (
                  <Typography component="span" color="text.secondary" variant="body2">
                    Empty
                  </Typography>
                )}
                {perfume.email && (
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {perfume.email}
                  </Typography>
                )}
                {perfume.username && " — " + perfume.userLastName}
              </>
            }
          />
        </ListItemButton>
      ))}
    </List>
  );
}

export function adaptPerfumeSummary(hit: AlgoliaHit): PerfumeSummary {
  const property = (fieldName: keyof PerfumeSummary): string => hit[fieldName] || "";

  return {
    // ...hit,
    recipeId: hit.objectID,
    formulaNumber: property("formulaNumber"),
    name: property("name"),
    username: property("username"),
    email: property("email"),
    userLastName: property("userLastName"),
    created: new Date(property("created")),
  };
}
