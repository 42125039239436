import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { FirebaseWrapper } from "@/infra/FirebaseWrapper";
import { initSentry } from "@/infra/SentryWrapper";
import * as Sentry from "@sentry/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Repositories } from "@/repositories";
import { initServices, Services } from "@/injectServices";
import { appRoutes } from "./AppRoutes";
import { initializeI18n } from "@/appl18n";

import "./index.css";

const environmentName = import.meta.env.MODE;
console.log(`Using environment ${environmentName}`);
const devEnvironment = environmentName === "development";
const connectEmulators = devEnvironment;
const enableSentry = !devEnvironment;

if (enableSentry) {
  initSentry();
}

initializeI18n();

const firebaseWrapper = new FirebaseWrapper(connectEmulators);
const repositories = new Repositories(firebaseWrapper);
initServices(new Services(repositories));

const createBrowserRouterFn = enableSentry
  ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  : createBrowserRouter;
const router = createBrowserRouterFn(appRoutes());

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
