import { useMemo, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  type Perfume,
  type PerfumeIngredient,
  AvailablePerfumeIngredients,
  allAvailableIngredients,
  perfumeVolume,
  PageLink,
  adaptPerfumeOrgan,
  adaptPerfumeWorkshopLocation,
  buildIngredients,
  type CategoriesOfIngredients,
  makeIngredient,
  PerfumeTunnelPageStepId,
} from "@/models";
import { showLoader, type LoadingDispatcher } from "@/hooks/Loading";
import { PerfumeConverter } from "@/repositories/PerfumeDb";
import { type PerfumeRecipiesService } from "./PerfumeRecipiesService";
import type { EditPerfumeViewModel, PerfumeEditor } from "./perfume-editor";
import { useEffectOnceInStrictMode } from "@/hooks/useEffectOnceInStrictMode";

export function usePerfumeEditor({
  service,
  loadingDispatcher,
  backendPerfume,
  setBackendPerfume,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  backendPerfume: Perfume;
  setBackendPerfume: (perfume: Perfume) => void;
}): PerfumeEditor {
  const [perfumeViewModel, setPerfumeViewModel] = useState<EditPerfumeViewModel>(() => {
    const customIngredients: PerfumeIngredient[] = [];

    return {
      name: backendPerfume.name,
      email: backendPerfume.email,
      title: backendPerfume.title,
      username: backendPerfume.username,
      userLastName: backendPerfume.userLastName,
      components: backendPerfume.components.map((backendComponent) => {
        const [ingredient, created] = makeIngredient(backendComponent, allAvailableIngredients, {
          createMissingIngredient: true,
        });
        if (ingredient !== null && created) {
          customIngredients.push(ingredient);
        }

        return {
          componentId: uuidv4(),
          category: backendComponent.category,
          ingredient: ingredient,
          volume: backendComponent.volume.toString(),
          displayName: "",
        };
      }),
      customIngredients: customIngredients,
      ingredientDialogOpen: null,
    };
  });

  const updatedPerfume: Perfume = useMemo(
    () => ({
      recipeId: backendPerfume.recipeId,
      formulaNumber: backendPerfume.formulaNumber,
      created: backendPerfume.created,
      name: perfumeViewModel.name,
      email: perfumeViewModel.email,
      title: perfumeViewModel.title,
      username: perfumeViewModel.username,
      userLastName: perfumeViewModel.userLastName,
      components: perfumeViewModel.components.map((component) => ({
        name: component.ingredient?.name ?? "",
        volume: parseInt(component.volume) || 0,
        category: component.category,
      })),
      workshopLocation: backendPerfume.workshopLocation,
      perfumeOrgan: backendPerfume.perfumeOrgan,
    }),
    [backendPerfume, perfumeViewModel],
  );

  const savePerfume = useCallback(async () => {
    // mini optim: compare with backendPerfume to avoid unnecessary save
    if (perfumeDeepEqual(updatedPerfume, backendPerfume)) {
      return;
    }

    await showLoader(loadingDispatcher, service.perfumeRepository.savePerfume(updatedPerfume));

    // reload not necessary, it's simpler to just set backend perfume
    setBackendPerfume(updatedPerfume);
  }, [
    backendPerfume,
    loadingDispatcher,
    service.perfumeRepository,
    setBackendPerfume,
    updatedPerfume,
  ]);

  const ingredients: CategoriesOfIngredients = useMemo(() => {
    return buildIngredients({
      ingredients: AvailablePerfumeIngredients,
      additionalIngredients: perfumeViewModel.customIngredients,
    });
  }, [perfumeViewModel.customIngredients]);

  return useMemo(
    () => ({
      updatePerfumeViewModel: setPerfumeViewModel,
      availableIngredients: ingredients,
      savePerfume,
      perfumeViewModel,
      totalVolume: perfumeVolume(updatedPerfume),
    }),
    [ingredients, perfumeViewModel, savePerfume, updatedPerfume],
  );
}

function deepEqual<T>(a: T, b: T): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

function perfumeDeepEqual(l: Perfume, r: Perfume): boolean {
  return (
    l.recipeId === r.recipeId &&
    deepEqual(PerfumeConverter.toFirestore(l), PerfumeConverter.toFirestore(r))
  );
}

export function createPerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId: string;
  organId: string;
}) {
  console.log(`Creating new perfume for workshop ${workshopLocationId} and organ ${organId}`);

  const workshop = adaptPerfumeWorkshopLocation(workshopLocationId);
  if (workshop === null) {
    throw Error(`Unknown workshop: ${workshopLocationId}`);
  }

  const organ = adaptPerfumeOrgan(organId);

  return showLoader(
    loadingDispatcher,
    service.perfumeRepository.createPerfume(
      {
        recipeId: uuidv4(),
        formulaNumber: null,
        created: new Date(),
        name: "",
        email: "",
        title: "",
        username: "",
        userLastName: "",
        components: [],
        workshopLocation: workshop,
        perfumeOrgan: organ,
      },
      workshop.formulaNumberPrefix,
    ),
  );
}

export function useCreatePerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
  perfumeId,
  create,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId: string | null;
  organId: string | null;
  perfumeId: string | null;
  create: boolean;
}) {
  const navigate = useNavigate();

  const [cantCreatePerfumeError, setCantCreatePerfumeError] = useState<string>("");

  useEffectOnceInStrictMode(
    useCallback(() => {
      // create new perfume when no recipeId is provided
      (async function () {
        if (perfumeId !== null) {
          return;
        }
        let navigateToPerfume: Perfume | null = null;

        if (workshopLocationId === null || organId === null) {
          setCantCreatePerfumeError("Please use a QR code to create a perfume");
          return;
        }

        if (!create) {
          const organHistory = await service.perfumeRepository.listPerfumeOfOrgan({
            organId,
            limit: 1,
          });

          if (organHistory.length > 0) {
            const lastPerfumeFromHistory = organHistory[organHistory.length - 1];

            if (lastPerfumeFromHistory !== null) {
              navigateToPerfume = lastPerfumeFromHistory;
            }
          }
        }

        if (navigateToPerfume === null) {
          navigateToPerfume = await createPerfume({
            service,
            loadingDispatcher,
            workshopLocationId,
            organId,
          });
        }

        navigate(
          PageLink.perfumeWorkshop({
            perfumeId: navigateToPerfume.recipeId,
            step: PerfumeTunnelPageStepId.Identification,
          }),
          {
            replace: true,
          },
        );
      })();
    }, [perfumeId, workshopLocationId, organId, create, service, navigate, loadingDispatcher]),
  );

  return {
    cantCreatePerfumeError,
  };
}

export function useCreateNewPerfume({
  service,
  loadingDispatcher,
  workshopLocationId,
  organId,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  workshopLocationId?: string;
  organId?: string;
}) {
  const navigate = useNavigate();

  const canCreateNewPerfume = workshopLocationId && organId;

  const createNewPerfume = async () => {
    if (canCreateNewPerfume) {
      const createdPerfume = await createPerfume({
        service,
        loadingDispatcher,
        workshopLocationId,
        organId,
      });

      navigate(PageLink.perfumeWorkshop({ perfumeId: createdPerfume.recipeId }));
    }
  };

  return {
    createNewPerfume,
    canCreateNewPerfume,
  };
}
