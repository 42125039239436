import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import { PageLink } from "@/models";
import { services } from "@/injectServices";
import { useLastPerfumes, deleteAllEmptyPerfumeRecipies } from "@/services";
import { LoadingDispatcher, useLoading } from "@/hooks/Loading";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PerfumeSearch from "@/components/PerfumeSearch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListOfPerfumesSearchHits from "@/components/ListOfPerfumesSearchHits";

const numberOfLastPerfumes = 100;

function LastPerfumes({
  pageLoadingDispatcher,
  selectPerfumeId,
}: {
  pageLoadingDispatcher: LoadingDispatcher;
  selectPerfumeId: (perfumeId: string) => void;
}) {
  const { recipies } = useLastPerfumes(
    services().perfumeService,
    pageLoadingDispatcher,
    numberOfLastPerfumes,
  );

  return (
    <>
      <Typography variant="h5">Les {numberOfLastPerfumes} derniers parfums créés</Typography>
      <ListOfPerfumesSearchHits perfumes={recipies} selectPerfumeId={selectPerfumeId} />
    </>
  );
}

export function PerfumeListPage() {
  useEffect(() => {
    document.title = `Galimard Workshop: Perfumes list`;
  });

  const [pageLoading, pageLoadingDispatcher] = useLoading();

  // --- load perfumes

  const perfumeService = services().perfumeService;

  const deleteEmptyRecipies = useCallback(async () => {
    await deleteAllEmptyPerfumeRecipies(perfumeService, pageLoadingDispatcher);
    // await refreshRecipies();
  }, [perfumeService, pageLoadingDispatcher]);

  // --- navigate to perfume details

  const navigate = useNavigate();
  const handleSelectPerfume = useCallback(
    (perfumeId: string) => {
      navigate(PageLink.perfumeDetails(perfumeId));
    },
    [navigate],
  );

  const [visibleTab, setVisibleTab] = useState(0);

  const handleTabChanged = useCallback((event: SyntheticEvent, newVisibleTab: number) => {
    setVisibleTab(newVisibleTab);
  }, []);

  return (
    <Container>
      <DelayedLinearProgress loading={pageLoading} />
      <Tabs value={visibleTab} onChange={handleTabChanged} aria-label="basic tabs example">
        <Tab label={`${numberOfLastPerfumes} derniers parfums`} />
        <Tab label="Rechercher un parfum" />
        <Tab label="Maintenance" />
      </Tabs>

      <Divider sx={{ m: "24px 0px" }} />

      {visibleTab === 0 && (
        <LastPerfumes
          pageLoadingDispatcher={pageLoadingDispatcher}
          selectPerfumeId={handleSelectPerfume}
        />
      )}
      {visibleTab === 1 && <PerfumeSearch selectPerfumeId={handleSelectPerfume} />}

      {visibleTab === 2 && (
        <Stack direction="row">
          <Button onClick={deleteEmptyRecipies}>Supprimer les parfums vides</Button>
        </Stack>
      )}
    </Container>
  );
}
