import { Fragment, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import QRCode from "react-qr-code";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import PlaceIcon from "@mui/icons-material/Place";
import TableRestaurant from "@mui/icons-material/TableRestaurant";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddBox from "@mui/icons-material/AddBox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid2";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";

import { PageLink, availableWorkshopLocations, userIsAdmin } from "@/models";
import type { PerfumeOrgan, WorkshopLocation } from "@/models";
import { useLastPerfumeOfOrgan, LastPerfumeStatus, useAuthentication } from "@/services";
import { services } from "@/injectServices";
import { type LoadingDispatcher, useLoading } from "@/hooks/Loading";
import DelayedLinearProgress from "@/components/DelayedLinearProgress";
import { FormulaNumber } from "@/components/formulaNumber";

const localStorageLogoClickCountKey = "home-logo-click-count";

export function HomePage() {
  useEffect(() => {
    document.title = `Galimard Workshop: Home`;
  });

  const [pageLoading, pageLoadingDispatcher] = useLoading();
  const [logoClickCount, setLogoClickCount] = useState<number>(() => {
    // retrive existing click count
    return parseInt(localStorage.getItem(localStorageLogoClickCountKey) || "0") || 0;
  });

  // store logoClickCount in local storage
  useEffect(() => {
    localStorage.setItem(localStorageLogoClickCountKey, `${logoClickCount}`);
  }, [logoClickCount]);

  const { user } = useAuthentication(services().authenticationService);

  const showOrgans = logoClickCount > 4 && userIsAdmin(user);

  const orderWorkshopLink = "https://www.galimard.com/categorie-produit/ateliers-paris";

  return (
    <Container>
      <DelayedLinearProgress loading={pageLoading} />

      {
        !showOrgans && (
          <Card sx={{ maxWidth: "350px", mx: "auto", mt: 2 }}>
            <CardMedia
              onClick={() => setLogoClickCount((c) => c + 1)}
              component="img"
              // height="140"
              image="/atelier-grasse-particuliers-550x371.jpg"
              alt="galimard logo"
            />
            <CardActionArea href={orderWorkshopLink}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Atelier de création de Parfum à Paris
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Créez votre fragrance unique et repartez avec votre Eau de Parfum 100ml
                  personnalisée.
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary" href={orderWorkshopLink}>
                Réservation sur galimard.com
              </Button>
            </CardActions>
          </Card>
        )
        // <Box display="flex" alignItems="center" flexDirection="column" sx={{}}>
        //   <Box
        //     sx={{
        //       maxWidth: "200px",
        //     }}
        //     onClick={() => setLogoClickCount((c) => c + 1)}
        //     component="img"
        //     src="/galimard-logo-noir-sans-baseline.svg"
        //   />

        //   <Typography>Perfume creation workshop</Typography>
        //   <Box component="img" src="/atelier-grasse-particuliers-550x371.jpg"/>
        //   <a href="https://www.galimard.com/categorie-produit/ateliers-paris"><Typography>Réservation sur galimard.com</Typography></a>
        // </Box>
      }

      {showOrgans && (
        <Paper
          sx={{
            m: 1,
            p: 2,
          }}
        >
          <List
            component="nav"
            subheader={<ListSubheader>Workshops Locations and organs</ListSubheader>}
          >
            {availableWorkshopLocations.map((w) => (
              <Fragment key={w.workshopLocationId}>
                <ListItem>
                  <ListItemIcon>
                    <PlaceIcon />
                  </ListItemIcon>
                  <ListItemText primary={w.name} secondary={w.address} />
                </ListItem>
                <Grid
                  container
                  sx={{ flexGrow: 1 }}
                  spacing={{
                    xs: 2,
                    md: 4,
                  }}
                >
                  {w.perfumeOrgans.map((organ) => (
                    <Grid
                      size={{ xs: 12, sm: 6 }}
                      key={`${w.workshopLocationId}_${organ.perfumeOrganId}`}
                    >
                      <OrganItem
                        workshopLocation={w}
                        organ={organ}
                        loadingDispatcher={pageLoadingDispatcher}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            ))}
          </List>
        </Paper>
      )}
    </Container>
  );
}

function OrganItem({
  workshopLocation,
  organ,
  loadingDispatcher,
}: {
  workshopLocation: WorkshopLocation;
  organ: PerfumeOrgan;
  loadingDispatcher: LoadingDispatcher;
}) {
  const workshopLocationOrganLink = PageLink.workshopLocationOrgan({
    workshopLocationId: workshopLocation.workshopLocationId,
    organId: organ.perfumeOrganId,
  });

  const workshopLocationOrganLinkCreate = `${workshopLocationOrganLink}?create=1`;

  const organURLCreate = useMemo(() => {
    return new URL(workshopLocationOrganLinkCreate, window.location.origin).toString();
  }, [workshopLocationOrganLinkCreate]);

  const { lastPerfume } = useLastPerfumeOfOrgan({
    service: services().perfumeService,
    loadingDispatcher,
    organId: organ.perfumeOrganId,
  });

  const perfume =
    lastPerfume === LastPerfumeStatus.Fetching ||
    lastPerfume === LastPerfumeStatus.Error ||
    lastPerfume === LastPerfumeStatus.NoLastPerfum
      ? null
      : lastPerfume;

  const lastPerfumeStatusText =
    lastPerfume === LastPerfumeStatus.Fetching ? (
      <>...</>
    ) : lastPerfume === LastPerfumeStatus.Error ? (
      <>Error</>
    ) : lastPerfume === LastPerfumeStatus.NoLastPerfum ? (
      <>No last perfume</>
    ) : (
      <FormulaNumber formulaNumber={lastPerfume.formulaNumber} />
    );

  const lastPerfumeLink = useMemo(() => {
    return perfume
      ? new URL(
          PageLink.perfumeWorkshop({ perfumeId: perfume.recipeId }),
          window.location.origin,
        ).toString()
      : null;
  }, [perfume]);

  const [showQRCode, setShowQRCode] = useState<boolean>(false);

  return (
    <Card sx={{ minWidth: 250, backgroundColor: "#fafafa" }}>
      <CardHeader
        avatar={
          <RouterLink to={workshopLocationOrganLink}>
            <TableRestaurant />
          </RouterLink>
        }
        title={`Organ ${organ.name}`}
        // subheader={lastPerfumeLink && <Link component={RouterLink} to={lastPerfumeLink}>{lastPerfumeStatusText}</Link>}
      />

      <CardContent>
        {lastPerfumeLink && (
          <>
            Dernier parfum créé à cet organ:{" "}
            <Link component={RouterLink} to={lastPerfumeLink}>
              {lastPerfumeStatusText}
            </Link>
          </>
        )}
      </CardContent>
      {showQRCode && (
        <CardMedia
          component={QRCode}
          size={750}
          value={organURLCreate}
          level="H"
          sx={{
            width: 1,
            height: "250px",
          }}
        />
      )}
      <CardActions>
        <Button onClick={() => setShowQRCode((show) => !show)}>
          {showQRCode ? "Cacher" : "QRCode"}
        </Button>
        <Button
          component={RouterLink}
          sx={{
            marginLeft: "auto",
          }}
          to={organURLCreate}
          size="small"
          startIcon={<AddBox />}
        >
          Créer un parfum
        </Button>
      </CardActions>
    </Card>
  );
}
