import { useCallback, useEffect, useState } from "react";
import type { PerfumeRepositoryFirebase } from "@/repositories";
import type { Perfume, PerfumeIngredient } from "@/models";
import {
  allAvailableIngredients,
  isPerfumeEmpty,
  makeIngredient,
  removeEmptyComponents,
} from "@/models";
import { useRunOnce, useSimpleLoader } from "@/hooks/SimpleLoader";
import { showLoader, type LoadingDispatcher } from "@/hooks/Loading";

export interface PerfumeRecipiesService {
  perfumeRepository: PerfumeRepositoryFirebase;
}

export function usePerfume({
  service,
  loadingDispatcher,
  perfumeId,
  formulaNumber,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  perfumeId?: string;
  formulaNumber?: string;
}) {
  const perfumeLoader = useCallback(async () => {
    let perfume: Perfume | null;
    if (perfumeId !== undefined) {
      perfume = await service.perfumeRepository.perfumeById(perfumeId);
    } else if (formulaNumber !== undefined) {
      perfume = await service.perfumeRepository.perfumeByFormulaNumber(formulaNumber);
    } else {
      return null;
    }

    if (perfume === null) {
      throw Error(`Perfume ${perfumeId || formulaNumber} not found`);
    }
    return perfume;
  }, [perfumeId, formulaNumber, service.perfumeRepository]);

  const [perfume, refreshPerfume, setPerfume] = useSimpleLoader<Perfume>(
    loadingDispatcher,
    perfumeLoader,
  );

  return {
    perfume,
    refreshPerfume,
    setPerfume,
  };
}

export async function deletePerfume(
  service: PerfumeRecipiesService,
  loadingDispatcher: LoadingDispatcher,
  recipeId: string,
) {
  await showLoader(loadingDispatcher, service.perfumeRepository.deletePerfume(recipeId));
}

export async function deleteAllEmptyPerfumeRecipies(
  service: PerfumeRecipiesService,
  loadingDispatcher: LoadingDispatcher,
) {
  const deleteRecipies = (async () => {
    const perfumes = await service.perfumeRepository.listPerfume();
    for (const perfume of perfumes || []) {
      if (isPerfumeEmpty(perfume)) {
        await service.perfumeRepository.deletePerfume(perfume.recipeId);
      }
    }
  })();

  await showLoader(loadingDispatcher, deleteRecipies);
}

export function useLastPerfumes(
  service: PerfumeRecipiesService,
  loadingDispatcher: LoadingDispatcher,
  numberOfLastPerfumes: number = 1e6,
) {
  const recipiesLoader = useCallback(async () => {
    return service.perfumeRepository.listPerfume(numberOfLastPerfumes);
  }, [service.perfumeRepository, numberOfLastPerfumes]);

  const [recipies, refreshRecipies] = useSimpleLoader<Perfume[]>(loadingDispatcher, recipiesLoader);

  return {
    recipies,
    refreshRecipies,
  };
}

export enum LastPerfumeStatus {
  Fetching,
  NoLastPerfum,
  Error,
}

export type LastPerfume = Perfume | LastPerfumeStatus;

export function useLastPerfumeOfOrgan({
  service,
  loadingDispatcher,
  organId,
}: {
  service: PerfumeRecipiesService;
  loadingDispatcher: LoadingDispatcher;
  organId: string;
}) {
  const [lastPerfume, setLastPerfume] = useState<LastPerfume>(LastPerfumeStatus.Fetching);

  const fetchLastPerfumeOfOrgan = useCallback(async () => {
    setLastPerfume(LastPerfumeStatus.Fetching);

    try {
      const perfumes = await showLoader(
        loadingDispatcher,
        service.perfumeRepository.listPerfumeOfOrgan({
          organId: organId,
          limit: 1,
        }),
      );
      setLastPerfume(perfumes.length ? perfumes[0] : LastPerfumeStatus.NoLastPerfum);
    } catch {
      setLastPerfume(LastPerfumeStatus.Error);
    }
  }, [loadingDispatcher, organId, service.perfumeRepository]);

  useRunOnce(fetchLastPerfumeOfOrgan);

  return {
    lastPerfume,
  };
}

// export function useSearchPerfumes(
//   service: PerfumeRecipiesService, // eslint-disable-line @typescript-eslint/no-unused-vars
//   loadingDispatcher: LoadingDispatcher, // eslint-disable-line @typescript-eslint/no-unused-vars
// ) {
//   const [searchResults] = useState<Perfume[] | null>();

//   const searchPerfumes = useCallback(
//     async (search: string) => {
//       const searchResult = await showLoader(
//         loadingDispatcher,
//         service.perfumeRepository.searchPerfumes(search),
//       );
//       setSearchResults(searchResult);
//     },
//     [service.perfumeRepository, loadingDispatcher],
//   );

//   const searchPerfumesDebounced = useMemo(
//     () =>
//       debounce((search) => {
//         searchPerfumes(search);
//       }, 1000),
//     [searchPerfumes],
//   );

//   const [search, setSearch] = useState("");

//   useEffect(() => searchPerfumesDebounced(search), [searchPerfumesDebounced, search]);

//   const searchPerfumesNow = useCallback(async () => {
//     searchPerfumesDebounced.clear();
//     await searchPerfumes(search);
//   }, []);

//   return {
//     search,
//     setSearch,
//     searchPerfumesNow,
//     searchResults,
//   };
// }

type IngredientInventory = {
  ingredient: PerfumeIngredient;
  volumeUsed: number;
};

export function useIngredientsInventory(
  service: PerfumeRecipiesService,
  loadingDispatcher: LoadingDispatcher,
) {
  const { recipies } = useLastPerfumes(service, loadingDispatcher);
  const [inventory, setInventory] = useState<IngredientInventory[] | null>(null);

  useEffect(() => {
    if (recipies === null) {
      return;
    }

    const inventoryMap: Map<string, IngredientInventory> = new Map();
    const getIngredientInventory = (ingredient: PerfumeIngredient): IngredientInventory => {
      let ingredientInventory = inventoryMap.get(ingredient.name);
      if (ingredientInventory === undefined) {
        ingredientInventory = {
          ingredient,
          volumeUsed: 0,
        };
        inventoryMap.set(ingredient.name, ingredientInventory);
      }

      return ingredientInventory;
    };

    for (const component of recipies.flatMap((recipe) =>
      removeEmptyComponents(recipe.components),
    )) {
      const [ingredient] = makeIngredient(component, allAvailableIngredients);
      if (ingredient !== null) {
        getIngredientInventory(ingredient).volumeUsed += component.volume;
      }
    }

    const sortedInventory = [...inventoryMap.values()].sort((a, b) => a.volumeUsed - b.volumeUsed);
    setInventory(sortedInventory);
  }, [recipies]);

  return {
    inventory,
  };
}
